export const environment = {
  production: false,
  apiUrl: 'https://api-dev.elenasy.com/api/v1',
  firebaseConfig: {
    apiKey: 'AIzaSyDcrBvUQZfsTJkSf6mNouPfTwTLlMh5K-4',
    authDomain: 'elenasy-13e21.firebaseapp.com',
    projectId: 'elenasy-13e21',
    storageBucket: 'elenasy-13e21.appspot.com',
    messagingSenderId: '247612237338',
    appId: '1:247612237338:web:de7e2c00f81a5c384093a3',
  },
  VAPID_KEY:
    'BAbAr90fxYaE48Kw_j7yQ1DIxyPuSGehvLCQLAMnOTa91OG79rlLxkHb4WoXbEwHns-B8JpgfNx6JkiT4v8gilo',
  paystackPublicKey: 'pk_test_b4be0a699642135e74e0bca8869bc5124e313b30',
};
